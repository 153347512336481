import React from "react";
import "../StylesAbout/OurVision.css";
import founder from "../Assets/founder.png"

function OurVision() {
  return (
    <div className="ov-section" id="Ourvision">
      <div className="ov-title-content">
      <div className="ov-title-contentheading">
      <h1>Our Vision</h1>
      </div>
      

        <p className="ov-description">
        We are an Applied Analytics and Data Science solutions and services company that aims to bridge the gap between technological advancements and business value generation, primarily for the healthcare industry. We believe insight-generating analytics can turbocharge a company's performance beyond its investment and don't limit our job to just applying analytical rigor to client-supplied data; we actively seek opportunities to implement systems that capture the right data.

       </p>
      </div>
      <div className="ov-title-content">
      <div className="ov-title-contentheading">
      <h1>Our LeaderShip</h1>
      </div>
        

        <p className="ov-leadership-description">
        At the core of our company is a leadership team with a proven track record of delivering excellence:
        <ul className="ov-leadership-list">
        <li className="ov-leadership-item">


        <span style={{fontWeight: 'bolder'}}>Expertise That Drives Innovation:</span> Led by an IIM Bangalore graduate with extensive experience in Al and data science, including leadership roles at a Fortune 5 company.
        </li>

        <li className="ov-leadership-item">

        <span style={{fontWeight: 'bolder'}}>Established Al Excellence:</span> Built and scaled Al capabilities across 30+ teams, delivering transformative business outcomes globally.
        </li>
        <li className="ov-leadership-item">

        <span style={{fontWeight: 'bolder'}}>Patented Innovator:</span> Holder of 5+ patents, demonstrating a commitment to cutting-edge solutions and industry leadership.
        </li>
        <li className="ov-leadership-item">

        <span style={{fontWeight: 'bolder'}}>Real-World Impact:</span> Guided businesses across diverse industries, translating complex data challenges into measurable success stories.
        </li>
        </ul>

        This experience forms the foundation of our company, ensuring we bring unparalleled expertise, innovative thinking, and proven strategies to every client partnership.

        </p>
      </div>

      
      {/* Founder Details Section */}

      <div className="founder-section">
        <div className="founder-img-section">
          <img className="founder-img" src={founder}></img>
        </div>
        <div className="founder-description">
          <ul className="founder-list">
            <li className="founder-list-item">Founder, Aidwise (Vision: Bridging Al and Outcomes Gap though Al services)</li>
            <li className="founder-list-item">US Patent in Clinical Workflow, 40U40 Data Scientist</li>
            <li className="founder-list-item">IIM Bangalore- MBA | London Business School- Executive</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default OurVision;
