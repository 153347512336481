import React from "react";
import "../Styles/About1.css";
import { useNavigate  } from "react-router-dom";
import "../StylesServices/OurProduct.css";
import Zeroshot from "../Assets/zero-shot.png";
// import "../StylesServices/Visualisation.css";


function Visualisation() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/ProductKnow");
  };
  return (
    <div className="op-outer">
    <div className="op-section" id="visualisation">
      <div className="op-image-content">
        <img src={Zeroshot} alt="invoice" className="side-img-service"/>
      </div>
  
      <div className="op-text-content">
      <p className="op-head">
      Visualisation
        </p>
  
        <h3 className="op-title">
          <span>Discover how we transform data into actionable insights through powerful visuals</span>
        </h3>
        <p className="op-description">
        Transforming complex stories into impactful visuals, focusing on business-critical metrics and enabling deep-dive insights for informed decisions
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
  
}

export default Visualisation;

