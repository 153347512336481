import React from "react";
// import "../StylesProducts/BluePrint.css";
import "../StylesProducts/OurProduct.css";
import trend from "../Assets/trend.png"

function CoPilot() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="copilot">
      <div className="ops-image-content">
        <img src={trend} alt="trend" className="sides-img"/>
      </div>
  
      <div className="ops-text-content">
      <p className="ops-head">
      CO-PILOTS CRAFTED FOR YOU 
        </p>
  
        <h3 className="ops-title">
          <span>Tailored AI Assistants for Your Business</span>
        </h3>
        <p className="ops-description">
        Our Co-Pilots are advanced Al assistants meticulously designed to address your unique business needs. By leveraging cutting-edge technology, they enhance productivity and efficiency across your organization.
  
        </p>
        <p className="ops-description">
        From automating repetitive tasks to providing data-driven insights, It empower your team to make informed decisions, ultimately driving your business success.
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
}

export default CoPilot;

