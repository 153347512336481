import React from "react";
import "../Styles/Contact.css";

function Contact() {
  return (
    <div className="contact-outer">
    <div className="contact-section" id="contact">
      <div className="contact-image-content">
        <h3 className="contact-title">
          See why Aidwise is <br/>Recognized as a leader in <br/>Al-driven Businesses
        </h3>
      </div>

      <div className="contact-text-content">
      
        
        
        <button
            className="contact-btn"
            type="button"
          >
            Contact for Further Assistance
          </button>
      </div>
    </div>
    </div>
  );
}

export default Contact;
