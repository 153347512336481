import React from "react";
import Navbar from "../Components/Navbar";
import UpperMid from "../Components/UpperMid";
import Hero from "../Components/Hero";
import About2 from "../Components/About2";
import About1 from "../Components/About1";
import AboutDark from "../Components/AboutDark";
import OurClient from "../Components/OurClient";
import OurValues from "../Components/OurValues";
import Testimonials from "../Components/Testimonials";
import ProjectInfo from "../Components/ProjectInfo";
import Contact from "../Components/Contact";
import Footer from "../Components/Footer";

function Home() {
  return (
    <div className="home-section">
      <Navbar />
      <Hero />
      <UpperMid />
      <About1 />
      <About2 />
      <AboutDark />
      <OurClient />
      <ProjectInfo />
      <OurValues />
      <Testimonials />
      <Contact />
      <Footer />

    </div>
  );
}

export default Home;
