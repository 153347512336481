import React from "react";
import TlInfoCard2 from "./TlInfoCard2";
import TlCard from "./TlCard";
import TlInfoCard1 from "./TlInfoCard1";

import "../Styles/Testimonials.css";

function Testimonials() {


  return (
    <div className="tl-outer">

    <div className="tl-section" id="testimonials">
    <div className="tl-content">
      <div className="tl-text-content">
        <p className="tl-text-title">
        <span className="tl-text-num">Testimonials</span>
        </p>
        <p className="tl-text-desc">What our customers are <br/>saying about us?</p>

      </div>
      <div className="tlinfo-card-content">
        <TlInfoCard1
          name="12+"
          desc1 = "Satisfied"
          desc2 = "Business partners"
        />
        <TlInfoCard2
          name="4.9"
          desc1 = "Overall Rating"
        />
      </div>
    </div>

        

      <div className="tl-cards-content">
        <TlCard
          title="Global Health Authority"
          description="The Visual Analytics Solutions and their Al Capabilities were very impressive. Highly capable team in helping navigate complexities of data"
        />

        <TlCard
          title="Leading Hospitals"
          description="They are partners of our Al journey to drive next generation clinical decision support system"
        />

        <TlCard
          title="Renowened consulting firms"
          description="They are our data streamlining partners helping create powerful data and data stories"
        />
      </div>
    </div>
    </div>
    
  );

}

export default Testimonials;
