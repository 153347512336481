import React from "react";
import "../StylesAbout/Office.css";
import hyderabad from "../Assets/hyderabad.png"
import noida from "../Assets/noida.png"

function Office() {
  return (
    <div className="office-section" id="Office">
      <div className="office-title-content">
      <h1>Solving Global Challenges, <br/>We Currently Operating from These Locations</h1>
      
      </div>

    

      <div className="office-address-outer">
      <div className="office-address-section">
        <div className="office-img-section">
          <img className="office-img" src={hyderabad}></img>
        </div>
        <div className="office-description">
        <span style={{fontWeight: 'bolder'}}>Hyderabad</span>
        <br/>T-Hub
        Plot No 1/C, Sy No 83/1, Raidurgam panmaktha
        Hyderabad Knowledge City, Serilingampally, Hyderabad, Telangana 500081
        </div>
      </div>

      <div className="office-address-section">
        <div className="office-img-section">
          <img className="office-img" src={noida}></img>
        </div>
        <div className="office-description">
        <span style={{fontWeight: 'bolder'}}>Noida,</span>
        <br/>Aidwise Analytica Pvt Limited, 1739, 16th Floor Block J, Sportswood, Sector 79, Noida, Gautam Buddha Nagar, Noida, UP-201301.
        </div>
      </div>
      </div>
    </div>
  );
}

export default Office;
