import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import circles from "../Assets/circles.png"

function TlCard(props) {
  return (
    <div className="tl-cards">
      
      <div className="tl-card-outer">
      <img src={circles} className="tl-card-img" alt="tl-card"/>
     
      <p className="tl-card-title">{props.title}</p>
      <p className="tlinfo-card-stars">
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "", paddingRight: "6px" }}
        />
        {props.stars}
      </p>
      </div>
      <p className="tl-card-description">{props.description}</p>
    </div>
  );
}

export default TlCard;