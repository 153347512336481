import React from "react";
import whoarewe from "../Assets/whoarewe.png";

import "../Styles/AboutDark.css";

function AboutDark() {
  return (
    <div className="aboutdark-outer">
    <div className="aboutdark-section" id="aboutdark">
      <div className="aboutdark-image-content">
        <img src={whoarewe} alt="Group of people" className="aboutdark-image1" />
      </div>

      <div className="aboutdark-text-content">
        <p className="aboutdark-description">
        Who we are & How We're Revolutionizing Al Innovation?
        </p>
        <h3 className="aboutdark-title">
        At Aidwise, we drive continuous innovation in <br/>Al-driven solutions. With expertise in Al and data science.
        </h3>
        <p className="aboutdark-description">
        We empower businesses to unlock the full potential of Al-driven solutions, <br/>enabling them to achieve their goals and drive transformative progress.
        </p>
        
      </div>
    </div>
    </div>
  );
}

export default AboutDark;
