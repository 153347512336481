import React from "react";
import OurValuesCard from "./OurValuesCard";
import collaboration from "../Assets/collaboration.png";
import customer from "../Assets/customer.png";
import excellence from "../Assets/excellence.png";
import innovative from "../Assets/innovative.png";
import sustainable from "../Assets/sustainable.png";
import integrity from "../Assets/integrity.png";
import "../Styles/OurValues.css";

function OurValues() {
  return (
    <div className="ourvalues-outer">
    <div className="ourvalues-section" id="ourvalues">
      <div className="ourvalues-title-content">
      <div className="ourvalues-title-contentheading">
      <h1>Our Values</h1>
      </div>
        <h3 className="ourvalues-title">
          <span>Guided by Integrity & Purpose</span>
        </h3>

        <p className="ourvalues-description">
        At Aidwise, we are driven by a strong set of values that shape every aspect of our business, ensuring <br/>transparency, ethical practices, and a shared commitment to making a positive impact.
        </p>
      </div>

      <div className="ourvalues-cards-content">
        <OurValuesCard
          img={integrity}
          name="Al-Driven Integrity"
           title="We uphold ethical Al practices, ensuring transparency and trust in every solution."
        />
        <div className="ov-vertical"> </div>
        <OurValuesCard
          img={innovative}
          name="Innovative Al Solutions"
           title="Delivering cutting-edge Al and data science solutions that drive transformation"
        />
        <div className="ov-vertical"> </div>
        <OurValuesCard
          img={collaboration}
          name="Collaboration"
          title="Leveraging diverse expertise to achieve shared success through Al-driven insights."
        />
      </div>


      <div className="ourvalues-cards-content">
        <OurValuesCard
          img={customer}
          name="Customer-Centric Al"
           title="Tailoring Al solutions to meet clients' unique needs and exceed expectations."
        />
        <div className="ov-vertical"> </div>
        <OurValuesCard
          img={sustainable}
          name="Sustainable Al Impact"
           title="Driving responsible Al development for long-term, sustainable business impact"
        />
        <div className="ov-vertical"> </div>
        <OurValuesCard
          img={excellence}
          name="Excellence in Al & Data Science"
          title="Continuously raising the bar in Al and data science to deliver exceptional results."
        />
      </div>
    </div>
    </div>
  );
}


export default OurValues;
