import React from "react";
import "../Styles/UpperMid.css";

function Info() {
  return (
    <div className="uppermid-section" id="uppermid">
      <div className="uppermid-title-content">
        <span className="uppermid-title">
          In a world of <span style={{color: '#8348f0'}}>rising complexity</span>, a <span style={{color: '#8348f0'}}>New Innovative Approach to <br/>Problem Solving</span> is needed for Scalable & Systematic Decision-Making.
        </span>
      </div>
    </div>
  );
}

export default Info;
