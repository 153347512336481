import React from "react";
// import "../StylesProducts/BluePrint.css";
import "../StylesProducts/OurProduct.css";
import datazone from "../Assets/datazone.png"

function Zone() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="zone">
      <div className="ops-image-content">
        <img src={datazone} alt="data-zone" className="sides-img"/>

      </div>
  
      <div className="ops-text-content">
      <p className="ops-head">
        AI DATA ANALYTICS ZONE
        </p>
  
        <h3 className="ops-title">
          <span>Unleasing the potential of Big data with AI</span>
        </h3>
        <p className="ops-description">
        Our Al Data Analytics Zone provides data analytics services powered by Al, including predictive analytics, anomaly detection, and pattern recognition.
        </p>
         <p className="ops-description">
          Helping you make informed decisions faster.
         </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
}

export default Zone;

