import React from "react";


function OurValuesCard(props) {
  return (
    <div className="ourvalues-card">
      <img src={props.img} alt={props.name} className="ourvalues-card-img" />
      <p className="ourvalues-card-name">{props.name}</p>
      <p className="ourvalues-card-title">{props.title}</p>
    </div>
  );
}

export default OurValuesCard;