
import bgVideo from "../Assets/rotatingglobe.mp4";
import { useNavigate  } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/AboutUs");
  };

  return (
    <div className="section-outer">
    <div className="section-container">
      <p className="text-headline">Applied Analytics & Data Science Solutions Provider</p>
      <video autoPlay muted loop playsInline id="bg-video"><source src={bgVideo} type="video/mp4" /></video>
      <div className="hero-section">
        <div className="text-section">
          <h2 className="text-title">
          
          Empower Your Business <br/>With Insights, Not Just Data
          </h2>
          <p className="text-descritpion">
          Effortlessly enhance decision-making with Al, combining superior data quality, <br/>curating problem formulation, creating strong data foundation and <br/>intelligent solutions.
          </p>
          <button
            className="hero-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
             Contact Us
          </button>
          
        </div>

       
      </div>

    </div>
    </div>
  );
}

export default Hero;
