import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function TlInfoCard2(props) {
  return (
    <div className="tlinfo-card">
      <p className="tlinfo-card-name">{props.name}</p>
      <p className="tlinfo-card-desc1">{props.desc1}</p>
      <p className="tlinfo-card-desc2">{props.desc2}</p>

      <p className="tlinfo-card-stars">
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "rgb(162 29 217)", paddingRight: "6px" }}
        />
        <FontAwesomeIcon
          icon={faStar}
          style={{ color: "", paddingRight: "6px" }}
        />
        {props.stars}
      </p>
    </div>
  );
}

export default TlInfoCard2;