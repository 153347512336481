import React from "react";

import OurVision from "../ComponentsAbout/OurVision";
import Upper from "../ComponentsAbout/Upper";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Office from "../ComponentsAbout/Office";
import AboutForm from "../ComponentsAbout/AboutForm";

function AboutUs() {
  return (
    <div className="home-section">
      
      
      <Navbar />
      <Upper />
      <OurVision />
      <Office />
      <AboutForm />   
      <Footer />

    </div>
  );
}

export default AboutUs;