import React from "react";
// import "../StylesProducts/BluePrint.css";
import "../StylesProducts/OurProduct.css";
import invoice from "../Assets/invoice.png";


function HealthCare() {
  return (
    <div className="ops-outer">
    <div className="ops-section" id="healthcare">
      <div className="ops-image-content">
        <img src={invoice} alt="invoice" className="sides-img"/>
 
      </div>
  
      <div className="ops-text-content">
      <p className="ops-head">
      HEALTHCARE
        </p>
  
        <h3 className="ops-title">
          <span>AI-Powered innovations for Healthcare Excellence</span>
        </h3>
        <p className="ops-description">
        Leverage our advanced Al solutions to enhance patient outcomes and optimize healthcare operations.
  
        </p>
        <p className="ops-description">
        Aidwise's Al technology is designed for the healthcare industry, enabling predictive analytics, clinical decision support, and data-driven patient care. Our solutions are compliant with industry regulations, ensuring privacy and security.
        </p>
  
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
  
}

export default HealthCare;

