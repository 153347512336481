import React from "react";
import AI from "../Assets/AI.mp4";
import "../Styles/About1.css";

function About1() {
  return (
    <div className="about1-outer">
    <div className="about1-section" id="about1">
      <div className="about1-image-content">
        <video autoPlay muted loop playsInline id="Ai-video" ><source src={AI} type="video/mp4" /></video>
      </div>

      <div className="about1-text-content">
      <p className="about1-description">
        At Aidwise, We call it - 
        </p>

        <h3 className="about1-title">
          <span>The Science of <br/>Al-Driven Problem Solving</span>
        </h3>
        <p className="about1-description">
        A well-articulated problem, translated effectively <br/>into analytics, drives innovation and actionable insights, <br/>setting the foundation for success..
        </p>

       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
    </div>
    </div>
  );
}

export default About1;
