import React from "react";
function StatsCard(props) {
  return (
    <div className="stats-card">
      <p className="stats-card-name">{props.name}</p>
      <p className="stats-card-name">{props.digit}</p>
      <p className="stats-card-name">{props.digit2}</p>
   
    </div>
  );
}

export default StatsCard;