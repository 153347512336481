import React from "react";
import "../StylesAbout/Office.css";

import "../StylesAbout/AboutForm.css";

function AboutForm() {
  return (
    <div className="aboutform-section" id="Aboutform">
      <div className="aboutform-title-content">
      <h1>Have an analytics need or want to know more about our services?</h1>
      
      </div>


      
      <div className="aboutform-outer-section">
        <div className="aboutform-head-section">
          Please Fill the form
        </div>
        <div className="aboutform-description">
        <form className="aboutform-content">
            <input className="aboutform-input" placeholder="Name*"></input>
            <input className="aboutform-input" placeholder="Organization*"></input>
            <input className="aboutform-input" placeholder="Job Title*"></input>
            <input className="aboutform-input" placeholder="Email Address*"></input>
            <input className="aboutform-input" placeholder="Phone Number*"></input>
        </form>
        </div>

      </div>
    </div>
  );
}

export default AboutForm;

