import React from "react";

import AiConsulting from "../ComponentServices/AiConsulting.js";
import DifferentiateAI from "../ComponentServices/DifferentiateAI.js";
import EngineerData from "../ComponentServices/EngineerData.js";
import Operationalize from "../ComponentServices/Operationalize.js";
import Visualisation from "../ComponentServices/Visualisation.js";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";


function Services() {
  return (
    <div className="home-section">
      <Navbar />
      <AiConsulting />
      <EngineerData />
      <DifferentiateAI />
      <Visualisation />
      <Operationalize />
      
      <Footer />

    </div>
  );
}

export default Services;