import React from "react";


function TlInfoCard1(props) {
  return (
    <div className="tlinfo-card">
      <p className="tlinfo-card-name">{props.name}</p>
      <p className="tlinfo-card-desc1">{props.desc1}</p>
      <p className="tlinfo-card-desc2">{props.desc2}</p>


    </div>
  );
}

export default TlInfoCard1;