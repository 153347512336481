import React from "react";
import OurClientCard from "./OurClientCard";
import union from "../Assets/union.png";
import "../Styles/OurClient.css";
import bars from "../Assets/bars.png"

function OurClient() {
  return (
    <div className="ourclient-outer">
      <img src={bars} className="barsimg1" alt="bars"/>
    <div className="ouclient-section" id="ourclient" >
      <div className="ourclient-title-content">
      <div className="ourclient-title-contentheading">
        <h1>Our Clients</h1>
      </div>
        <h3 className="ourclient-title">
          <span>Trusted by Industry Leaders</span>
        </h3>

        <p className="ourclient-description">
        Join our roster of satisfied clients and experience the exceptional results and service that <br/>have earned us the trust of industry leaders worldwide in Al Analytics & Data Science
        </p>
      </div>

      <div className="ourclient-cards-content">
        <OurClientCard
          img={union}
          name="Global Health Authorities"
        />
        <OurClientCard
          img={union}
          name="Leading Hospitals and Diagnostics Networks"
        />
        <OurClientCard
          img={union}
          name="Renowned Consulting Firms"
        />
        <OurClientCard
          img={union}
          name="Pioneering Data Analytics Companies"
        />
      </div>
    </div>
    <img src={bars} className="barsimg2" alt="bars"/>
    </div>
  );
}

export default OurClient;
