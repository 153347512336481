import React from "react";
import "../Styles/About2.css";
import AI from "../Assets/AI.mp4";

function About2() {
  return (
    <div className="about2-outer">
    <div className="about2-section" id="about2">

      <div className="about2-text-content">
        <h3 className="about2-title">
          <span>From Source to Insight</span>
        </h3>
        <p className="about2-description">
        We build strong data foundations by understanding <br/> data from its origin to consumption, transforming it into <br/>actionable insights.
        </p>


        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="about2-image-content">
        <video autoPlay muted loop playsInline id="Ai-video"><source src={AI} type="video/mp4" /></video>
      </div>
    </div>
    </div>
  );
}

export default About2;
