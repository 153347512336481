import React from "react";
import "../StylesServices/OurProduct.css";
import { useNavigate  } from "react-router-dom";
import aiconsulting from "../Assets/ai-consulting.jpg";
// import "../StylesServices/AiConsulting.css";

function AiConsulting() {
  const navigate = useNavigate();
  const handleBookAppointmentClick = () => {
    navigate("/ProductKnow");
  };
  return (
    <div className="op-outer">
    <div className="op-section" id="aiconsulting">
  
      <div className="op-text-content">
      <p className="op-head">
          AI Consulting
        </p>
  
        <h3 className="op-title">
          <span>Get expert guidance on the latest AI trends and innovations.</span>
        </h3>
        <p className="op-description">
        Solving the challenge of unclear analytics goals and underutilized data by offering problem formulation, storytelling, maturity assessment, and roadmap development for impactful AI consulting.
       </p>
       
        <button
            className="text-appointment-btn"
            type="button"
          >
            Know More
          </button>
      </div>
      <div className="op-image-content">
        <img src={aiconsulting} alt="zero-shot" className="side-img-service"/>
      </div>
    </div>
    </div>
  );
}

export default AiConsulting;

